:root {
  --gray300: rgba(208, 213, 221, 1);
  --white: #ffffff;
  --bodyText: #475467;
  --primaryColor: #7F56D9;
  --secondaryColor: #EC4A0A;
  --titleText:#1D2939;
  --lightText:#98A2B3
}
/***** general classes *****/
.titleText{color:#1D2939}
.bodyText{color:#475467}
.fw400{font-weight: 400;}
.fw500{font-weight: 500;}
.fw700{font-weight: 700;}
.fw800{font-weight: 800;}
.primaryColor{color:#7F56D9;}
.secondaryColor{color:#EC4A0A}
.textCenter{text-align:center}
.lightText{color:#98A2B3}

/***** buttons *****/
button.tertiaryButton{
  height: 40px;
  width: 220px;
  border: 1px solid var(--gray300);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#344054;
  background-color: transparent;
  transition: all 0.5s ease-in-out !important;
}
button.tertiaryButton span{font-weight: 700;}
button.tertiaryButton:hover,button.tertiaryButton:focus{
  background-color: #F9FAFB;
  border: 1px solid var(--gray300);
  color:#344054;
}

button.primaryButton{
  height: 40px;
  width: 220px;
  border: 1px solid var(--primaryColor);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#fff;
  background-color: var(--primaryColor);
  transition: 0.5s ease-in-out !important;
}
button.primaryButton span{font-weight: 700;}
button.primaryButton:hover,button.primaryButton:focus{
  background-color: #53389E;
  border: 1px solid #53389E;
  color:#fff;
  border-radius: 40px;
}
button.primaryButton:disabled{
  background-color: #D6BBFB !important;
  border: 1px solid #D6BBFB !important;
  color:"#1D2939" !important;
}

button.secondaryButton{
  height: 40px;
  width: 220px;
  border: 1px solid var(--secondaryColor);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color:#fff;
  background-color: var(--secondaryColor);
  transition: 0.5s ease-in-out !important;
}
button.secondaryButton span{font-weight: 700;}
button.secondaryButton:hover,button.secondaryButton:focus{
  background-color: #C4320A;
  border: 1px solid #C4320A;
  color:#fff;
  border-radius: 40px;
}
button.secondaryButton:disabled{
  background-color: #FEB273 !important;
  border: 1px solid #FEB273 !important;
  color:"#1D2939" !important;
}

body {
  margin: 0;
  font-family: "Gilmer", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#explorics-container .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#explorics-container .trigger:hover {
  color: #1890ff;
}

#explorics-container .user-option {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}


#explorics-container .logo {
  height: 32px;
  background: #f0f2f5;
  margin: 16px;
}

#explorics-container #site-layout .site-layout-background {
  background: #fff;
}
#explorics-container #site-layout {
  /* margin-left: 250px; */
  z-index: 1;
  transition: 0.2s;
}
.ant-layout.layout {
  margin-left: 80px;
  z-index: 1;
  transition: 0.2s;
}
.ant-layout.layoutCollapsed{
  margin-left: 280px;
}

.ant-btn[disabled]{
  background-color: transparent !important;
}

.ant-layout-sider-zero-width-trigger{
  position: absolute !important;
  top: 9px !important;
  right: -44px !important;
  z-index: 10 !important;
  color: black !important;
  background-color: white !important;
}

#explorics-container .ant-menu-inline > .ant-menu-item , #explorics-container .ant-menu-submenu-title{
  height: fit-content;
  display: flex;
  align-items: center;
}
#explorics-container .ant-menu-inline > .ant-menu-item:first-child{
  margin-top:0 !important;
}
#explorics-container .ant-menu-submenu-title{
  line-height: 26px;
}
#explorics-container .ant-menu-inline > li{
  margin: 18px 0 !important;
  padding:7px 0;
}
.subMenuItem .ant-menu-item > .ant-menu-item-only-child > li{
  padding-left: 34px !important;
}

.ant-menu-submenu-title .anticon{
  margin-right: 5px !important;
}
#explorics-container li.ant-menu-item-only-child{
  margin: 0 0 !important;
  padding: 10px 0 10px 60px !important;
}

#explorics-container .ant-menu-inline > .ant-menu-item > span {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
#explorics-container .ant-menu-inline > .ant-menu-item > span.anticon{
  margin-right: 5px;
}

#explorics-container .ant-menu-inline > .ant-menu-item > span > a{
    width: 100%;
    white-space: pre-line;
    text-align: center;
}

#explorics-container .ant-menu-dark .ant-menu.ant-menu-sub.ant-menu-inline {
  background: rgba(255, 255, 255, 0.05);
}


/* #explorics-container .ant-menu-inline,
#explorics-container .ant-menu-vertical,
#explorics-container .ant-menu-vertical-left {
  border-right: 1px solid #fff;
} */

.explorics-sider-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
  border-right: 1px solid #c9c9c9;
}
.sidebar-menu {
  margin-top: 15px !important;
  overflow: scroll;
  height: calc(100vh - 183px)
}

.sidebar-menu ::-webkit-scrollbar,
::-webkit-scrollbar {
  display: none !important;
}

.ant-divider-vertical{
  border-left: 1px #A9A9A9 solid !important;
}
.ant-divider-horizontal{
  border-top: 1px #A9A9A9 solid !important;
}
.main_background {
  background-color: rgb(240, 242, 245);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login_content {
  background-color: #ffffff;
  margin-top: 40px;
  width: 450px;
  border-radius: 5px;
  flex: initial !important;
  padding: 40px 30px 25px;
}

.company_logo {
  width: 100%;
  height: 164px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.company_logo svg {
  height: 100%;
}

.login_symbol {
  text-align: center;
  padding: 0px 0 5px;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.textfield_section {
  margin: 20px 0 0;
}

.user_textfield {
  padding: 30px 15px;
  margin: 15px 0;
}

.user_textfield > input {
  font-size: 22px;
}
.user_textfield > span {
  font-size: 22px;
  padding: 0 5px 0 0;
  color: #1890ff;
}
.user_textfield > .ant-input-suffix {
  padding: 0;
  color: #000000 !important;
}
.remember_labal > label > span,
.remember_labal > a {
  font-size: 18px;
}
.login_button {
  width: 100%;
  font-size: 22px !important;
  margin: 10px 0;
  padding: 10px 0 !important;
  height: auto !important;
}
.remember_forget_pass {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.register_label {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
}

.ant-form-item-label > label {
  display: block !important;
}
.create_main_div {
  min-height: 100vh !important;
  height: 100vh;
  width: 100vw;
  padding: 3em 0px 0px;
  background-color: rgb(246, 246, 246) !important;
  display: grid;
  grid-template-rows: 1fr 64px;
}

.create_account_content {
  background-color: #ffffff;
  margin-top: 40px;
  max-width: 600px;
  border-radius: 5px;
  flex: initial !important;
  padding: 24px;
}

.logoImage {
  width: 100%;
  height: 96px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.textfield_label {
  margin-bottom: 24px;
}
.textfield_with_label {
  padding: 11px 18px !important;
  font-size: 20px !important;
  line-height: normal;
}
.side_by_side_button {
  display: flex;
  margin-bottom: 24px;
}
.create_account_left_button {
  width: 100%;
  margin-left: 5px;
}
.create_account_right_button {
  width: 100%;
  font-size: 15px !important;
  margin-right: 5px;
  padding: 5px !important;
  height: auto !important;
}
.create_account_heading {
  color: #33444c !important;
  font-weight: 400 !important;
  font-size: 24px;
}

.label_text {
  font-weight: 400 !important;
  font-size: 16px !important;
}

input.xl {
  padding: 11px 18px;
  font-size: 18px;
}

.ant-form-item-label > label,
.ant-form-item-label > label > .ant-row {
  width: 100%;
}

.border_bottom {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 10px !important;
}
.right_side_label {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  text-align: right;
  align-self: flex-end;
}

.text_label {
  color: #303030;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10px;
}
.optional_icon {
  color: rgb(0, 0, 0, 0.5) !important;
}
.text_bottom {
  color: rgb(0, 0, 0, 0.25) !important;
}
.goal_textfield > span > span {
  padding: 5px;
}
.create-campaign .anticon-dollar-circle , .CreateBudgetProfile .anticon-dollar-circle{
  top: 22px !important;
}
.create-campaign .ant-drawer-header-title{
  flex-direction: row-reverse;
}
.CreateBudgetProfile .ant-drawer-header-title{
  flex-direction: row-reverse;
}
.anticon-dollar-circle{
  z-index: 12;
  top: 43px;
  left: 20px;
  font-size:18px;
}
.goal_textfield > input,
.goal_textfield > div > input {
  line-height: 1;
}
/* .revenue_calculation {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #f1f1f1;
} */

.arrow_sign {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.arrow_sign > span {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  align-items: center;
  vertical-align: middle;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  z-index: 100;
}
.arrow_sign > span > svg {
  font-size: 30px;
  color: gray;
}
.dropdown_button:last-child > span {
  border-left: none;
}

.dropdown_button:first-child > button {
  padding-right: 0 !important;
}
.ant-modal-header > div {
  text-align: center;
  font-size: 20px;
}
.ant-slider-track {
  background-color: #1890ff !important;
}
.ant-slider-handle {
  border-color: #1890ff !important;
}
.subMenuItem {
  font-size: 18px !important;
  /* height: 50px;
  padding: 5px 0 !important; */
}
.subMenuItem > div > span {
  font-size: 18px !important;
}
.subMenuItem > div {
  margin-top: 0;
  margin-bottom: 0;
}
/* .subMenuItem .ant-menu{
  font-size: 13px !important;
  padding: 7px !important;
} */
.subMenuItem .ant-menu-title-content{
  font-size: 14px !important;
  padding: 0 0 0 0px !important;
}
.marketing_source_block {
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  margin: 10px;
}

@media print {
  body {
    visibility: hidden;
  }
  main {
    visibility: visible;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print,
  main {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.budgetLeadsViewTableGearIcon {
  cursor: pointer;
  visibility: hidden;
}

.budgetLeadsViewTableRow:hover .budgetLeadsViewTableGearIcon {
  visibility: visible;
}

.deleteCampaignModal .ant-modal-content {
  height: unset !important;
}

.deleteCampaignModal .ant-modal-body {
  padding: 24px;
}

.preventNavModal .ant-modal-content {
  height: unset;
}

.preventNavModal .ant-modal-body {
  padding: 24px;
}

.preventNavModal .ant-modal-title {
  text-align: left;
}

.budgetLeadsViewTableFirstCell {
  display: flex;
}

.sub-container {
  padding: 15px;
  background: #fff;
  border-radius: 7px;
}

.revenue-sub-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.ant-input-number {
  height: 44px;
  border: 1px solid #dedede;
  border-radius: 7px !important;
}

.ant-slider-rail {
  background-color: rgba(15, 97, 219, 0.21) !important;
}

.goal_textfield .ant-input-number-input-wrap {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.total-won-text {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  display: block;
}
.total-won-count {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color: #0f61db;
  display: block;
}
.total-won-text-closed {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #0f61db;
  display: block;
}

.total-closed-won-section {
  border: 1px solid #e5e5e5;
  height: 94%;
  padding: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 7px;
}

.marketing_source_block .ant-typography + h5.ant-typography {
  margin-top: 0em !important;
}

.more-info {
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-radius: 7px;
  color: #a9a9a9;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: help;
  float: right;
}

.sourced-title {
  width: 100%;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #0F61DB !important;
}

.source-title-sales {
  color: #00AEC5 !important;
}
.sourced-amount {
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #303030;
}

.sourced-text {
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #727272;
}

.sales-won-amount {
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #00AEC5;
}
.marketing-won-amount {
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #0F61DB;
}

.right-arrow {
  position: absolute;
    top: 44%;
    margin-left: -26px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    padding: 10px;
}

.annual-goal-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 15px;
    color: #303030;
}
.annual-goal-header-text {
  font-family: Gilmer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
    color: #303030;
    padding-top: 20px;
    padding-left: 20px;
}
.annual-goal-sub-header-text {
  font-family: Gilmer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 30px;
}

.empty-funnel-section {
  border: 1px solid#e5e5e5;
  margin: 15px !important;
  border-radius: 7px;
}

.conversation-difference-dot {
  height: 10px;
  width: 10px;
  background-color: var(--secondaryColor);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-top: 13px;
}

.marketing-sourced-info-icon {
  padding-right: 118px;
}

.admin-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  }

  .analytics-tab .ant-tabs-nav {
    height: 60px;
  }
  .analytics-tab{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .analytical-tab-2 .ant-tabs-content-holder {
    background: #fff;
  }
  .analytical-tab-2 .ant-tabs-nav-wrap {
    padding-top: 15px;
  }
  .back-to-admin-link{
    border: 1px solid #D0D5DD;
    border-radius: 7px;
    padding: 10px;
  }
  .ant-popover-buttons{
    display: flex ;
    flex-direction: row-reverse ;
    justify-content: center;
  }

  .container {
    position: relative;
    text-align: center;
    color: white;
  }

  .centered {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 35px;
    border: 1px solid #727272;
    border-radius: 7px;
  }

  .preview-info {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #303030;
    padding-top: 20px;
  }

  .preview-info-buttons {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 22px;
  }

  .learn-more-btn {
    height: 38px !important;
    margin-left: 5px;
    border: 1px solid rgb(48, 48, 48) !important;
    color: rgb(48, 48, 48) !important;
  }

.setting-table .ant-descriptions-item-label , .setting-table .ant-descriptions-item-content{
  padding: 9px 19px !important;
}

.create-package-drawer .ant-drawer-header-title, .create-new-course .ant-drawer-header-title, .AddCompany .ant-drawer-header-title,
.CreateNewChannel .ant-drawer-header-title, .createAssetModal .ant-drawer-header-title
{
  flex-direction: row-reverse;
}


.wrapperBox {
  height: 100%;
}
#quick-sight{
  height: 100%;
}
.small-indicator{
  height: 5px;
  width: 5px;
  background-color: var(--primaryColor);
  border-radius: 10px;

  display: inline-block;
  margin-right: 8px;
  margin-top: 0px;
}

.dashboard-chart-skeleton{
  width: 24vw !important;
  height: 200px !important;
}
.ant-layout-sider-collapsed{
  flex: 0 0 0px;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 0px;
}
.filter__dropdown .ant-switch-checked{
  background-color:green;
}
.filter__menu li:hover{
  background-color: transparent;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item{
  align-items: center;
}
.social_images{
    border: 1px solid rgba(227, 227, 227, 1);
    padding: 1px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.social_default_image{
  background-color: rgba(239, 112, 70, 1);
  height: 36px;
  width:36px;
  border-radius: 4px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.panel-design{
  border-radius:7px;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 3px 6px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  background: #fff;
}
.executive-tabs .ant-tabs-nav,.executive-tabs .site-card-wrapper{
  border-radius:7px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}
.general-table .ant-table table tr th{border-radius: 0 !important; padding:15px 8px !important;}
.general-table .ant-table table tr th .ant-typography{
  color:var(--titleText);
  font-weight: 500;
}
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td
{
  border-right: 1px solid #D0D5DD !important;
  border-bottom: 1px solid #D0D5DD !important;
}
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.general-table .ant-table-summary > tr > th, .general-table .ant-table-summary > tr > td{
  border-bottom: 1px solid #D0D5DD !important;
  padding:10px 8px;
}
.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table{
  border:1px solid #D0D5DD !important;
}
.link-icon svg{
  color:var(--primaryColor)
}
.general-tab .ant-tabs-nav-wrap{background: #f5f7fa; background-color: #f5f7fa !important;}
.general-tab .ant-tabs-tab{padding:18px 0}
input.general-input{transition: 0.5s ease-in-out !important; color:#1D2939; border:1px solid #D0D5DD; height: 45px;}
textarea.general-input-textarea{transition: 0.5s ease-in-out !important; color:#1D2939; border:1px solid #D0D5DD;}
input.general-input:focus,textarea.general-input-textarea:focus{
  box-shadow: none !important;
  border:1px solid var(--primaryColor) !important;
}
input.general-input::placeholder,textarea.general-input-textarea::placeholder{
  color:#98A2B3
}
.general-input-select{transition: 0.5s ease-in-out !important;}
.general-input-select .ant-select-selector{
  height: 45px !important;
  color: #1D2939;
  border: 1px solid #D0D5DD !important;
  align-items: center;
}
.general-input-select.ant-select-focused .ant-select-selector{
  box-shadow: none !important;
  border: 1px solid #7F56D9 !important;
}
.general-input.ant-input-affix-wrapper-focused{
  box-shadow: none !important;
  border: 1px solid #7F56D9 !important;
}
.general-input.ant-input-password input{
  height: 45px !important;
  color: #1D2939;
  align-items: center;
}
.general-form label small{
  font-size: 16px;
  color:#1D2939;
  font-weight:500
}
.add-icon svg path{
  fill:var(--secondaryColor)
}
.add-icon svg path:last-child{
  fill:#fff
}
input.general-input[type=search]{
  transition: 0.5s ease-in-out; color:#1D2939
}
select.general-input:focus{
  box-shadow: none !important;
  border:1px solid var(--primaryColor) !important;
}
.general-tab .ant-form-item-control-input-content{
  display: flex;
  flex-wrap: wrap;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}
.ant-checkbox-checked::after{
  border:1px solid var(--primaryColor);
}
.revenue-planning-table table tbody tr td:first-child, .revenue-planning-table table tbody tr td:nth-child(3){text-align: center;}
.ant-slider-handle{
  border: 2px solid #7F56D9 !important;
}
.ant-slider-track {
  background-color: #7F56D9 !important;
}
input[type="checkbox"]{
  width:18px;
  height:18px;
  accent-color: #7F56D9;
  color:#1D2939;
  
}

.ant-radio-inner{width:20px !important; height:20px !important;}
.ant-radio-checked::after{    border: 1px solid #7F56D9 !important;}
.ant-radio-checked .ant-radio-inner{border-color: #7F56D9 !important;}
.ant-radio-inner::after{
  background-color: #7F56D9 !important;
}
input[type="checkbox"] + span{
  color:#1D2939;
  font-weight: 500;
  margin-left:2px;
  font-size: 17px;
}

.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th{
  border-right: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
}
/* .campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:first-child,
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:first-child{
  border-left:1px solid #D0D5DD;
} */
/* .campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:last-child,
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:last-child{
  border-right:1px solid #D0D5DD !important;
}
.campaign-table.general-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:nth-last-child(2){
  border-right:1px solid #D0D5DD !important;
} */

.campaign-table.general-table .ant-table.ant-table-bordered {
  border: 1px solid #D0D5DD !important;
}
.budget.custom-selected-tab-selected{
  color:#7F56D9 !important
}
.budget.custom-selected-tab{
  color:#1D2939 !important
}
.ant-switch-checked{
  background-color: #7F56D9 !important;
}
.budget-tab.general-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#7F56D9;
}
.ant-tabs-ink-bar{
  background: #F04438 !important;
  height:'2.5px'
}
.budget-tab.general-tab .ant-tabs-tab .ant-tabs-tab-btn{
  color:#1D2939;
}
.executive-tabs .ant-tabs-tab{
  transition: 0.5s ease-in-out !important;
  padding:20px 10px;
  margin:0 0 0 16px !important
}
.executive-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span{
  color:#7F56D9;
  font-weight: 700;
}
.executive-tabs .ant-tabs-tab .ant-tabs-tab-btn span{
  color:#1D2939;
  font-weight: 500;
  transition: 0.5s ease-in-out;
  font-size: 18px !important;
}
.executive-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn span, .executive-tabs .ant-tabs-tab:hover .ant-tabs-tab-btn{
  color:#7F56D9
}
.general-table.sales-table .ant-table.ant-table-bordered{
  border:none !important;
}
.general-tab.first-tab .ant-tabs-tab{transition: 0.5s ease-in-out;}
.general-tab.first-tab .ant-tabs-tab .ant-tabs-tab-btn{
  font-size: 17px;
  font-weight: 700;
}
.general-tab.first-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#7F56D9;
}
.general-tab.first-tab .ant-tabs-tab:hover .ant-tabs-tab-btn{
  color:#7F56D9;
}
.coaching-tab.general-tab.first-tab .ant-tabs-tab .ant-tabs-tab-btn div{
  color:#1D2939 !important
}
.coaching-tab.general-tab.first-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn div{
  color:#7F56D9 !important
}
.coaching-tab.general-tab.first-tab .ant-tabs-tab:hover .ant-tabs-tab-btn div{
  color:#7F56D9 !important
}
.coaching-card .ant-card-body{padding:0}
.coaching-design.panel-design{border-radius: 0;}
.ant-input-number.general-input, .ant-input-number.general-input input{height:45px; width:100% !important}
.ant-typography-edit svg{
  fill:#7F56D9;
}
.ant-input-number.general-input svg{
  margin-top:6px
}
.ant-descriptions.ant-descriptions-bordered.account_description_table table tr th{
  background-color: #f5f7fa !important; 
  border-right: 1px solid #D0D5DD !important;
  border-bottom: 1px solid #D0D5DD !important;
  padding:14px 19px !important;
  
}
.ant-descriptions.ant-descriptions-bordered.account_description_table table tr th span{
  font-weight:700 !important;
}
.account_description_table table tr td{
  
  border-bottom: 1px solid #D0D5DD !important;
}
.ant-menu.right-dropdown-menu li span a{
  color:#1D2939 !important;
  font-weight:500 !important
}
.ant-menu.right-dropdown-menu li.ant-menu-item-selected{
  background-color: #7F56D9;
}
.ant-menu.right-dropdown-menu li.ant-menu-item-selected a{
  color:#fff !important
}
.ant-menu.right-dropdown-menu li a{
  transition:0.5s ease-in-out !important
}
.ant-menu.right-dropdown-menu li{
  transition:0.5s ease-in-out !important
}
.ant-menu.right-dropdown-menu li:hover{
  background-color: #7F56D9 !important;
}
.ant-menu.right-dropdown-menu li:hover a{
  color:#fff !important
}
.adminCompanyTable .ant-table table thead tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.adminCompanyTable .ant-table table tr td small{
  color:#1D2939 !important;
}
.adminCompanyTable .ant-table table tr td small.company-name{
  color:#7F56D9 !important;
}
.general-table .ant-pagination .ant-pagination-item{
  width:30px !important;
  height:30px !important;
  border:1px solid #D0D5DD !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin:0 5px !important;
  transition:0.5s ease-in-out !important;
  
}
.general-table .ant-pagination .ant-pagination-item.ant-pagination-item-active{
  background:#7F56D9 !important;
  
}
.general-table .ant-pagination .ant-pagination-item.ant-pagination-item-active a{
  color:#fff !important;
}

.general-table .ant-pagination.mini .ant-pagination-item a{
  font-weight: 700;
  transition:0.5s ease-in-out !important;
}
.general-table .ant-pagination .ant-pagination-item:hover{
  background:#7F56D9 !important;
}
.general-table .ant-pagination .ant-pagination-item:hover a{
  color:#fff !important;
}
.general-table .ant-pagination .ant-pagination-prev button, .general-table .ant-pagination .ant-pagination-next button{
  width:30px !important;
  height:30px !important;
  border:1px solid #D0D5DD !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin:0 5px !important;
  color:#7F56D9 !important;
  transition:0.5s ease-in-out !important;
  background: transparent !important;
}
.general-table .ant-pagination .ant-pagination-prev:hover button,.general-table .ant-pagination .ant-pagination-next:hover button{
  color:#fff !important;
  background: #7F56D9  !important;
}
.user-company-table .ant-table table thead tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.user-company-table .ant-table table tr td small{
  color:#1D2939 !important;
}
.user-company-table .ant-table table tr td small.company-name{
  color:#7F56D9 !important;
}
.company-package-table .ant-table table thead tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.company-package-table  .ant-table table tr td small{
  color:#1D2939 !important;
}
.assign-package-table .ant-table table tr th, .assign-package-table .ant-table table tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.assign-package-table  .ant-table table tr td div{
  color:#1D2939 !important;
}
.assign-package-table  .ant-table table tr td div.assign-text{
  color:#7F56D9 !important;
}
.default-property-table .ant-table table tr th, .default-property-table .ant-table table tr th div span{
  color:#1D2939 !important;
  font-weight:500
}
.default-property-table .ant-table table tr td div{
  color:#1D2939 !important;
}
.default-property-table  .ant-table table tr td div.assign-text{
  color:#7F56D9 !important;
}
.coaching-company-table .ant-table table tr th{
  color:#1D2939 !important;
  font-weight:500
}
.coaching-company-table .ant-table table tr td{
  color:#1D2939 !important;
}
.input-num-icon svg{
  margin-top:6px !important;
  margin-right:2px !important;
}
.ant-modal-footer{display:flex; align-items:center; justify-content:center}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar{
  height:2px !important
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar{
  height:2px !important
}
