// Change the font color of menu items that aren’t selected / hovered to #646c79

// Change the background color of the sidebar to #e0e0e0

// Add a right border to the sidebar with properties: 1px solid #c9c9c9

// Change the body background color from the light blue to #f9f9f9

@import '~antd/dist/antd.less';
@import 'ThemeVariables.less';
// @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@path-fonts: './assets/fonts';

@font-face {
  font-family: 'Roboto';
  src: url('@{path-fonts}/Roboto/Roboto-Black.ttf');
  src: url('@{path-fonts}/Roboto/Roboto-Bold.ttf');
  src: url('@{path-fonts}/Roboto/Roboto-Medium.ttf');
  src: url('@{path-fonts}/Roboto/Roboto-Regular.ttf');
  // src: url('@{path-fonts}/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Gilmer';
  src: url('@{path-fonts}/Gilmer/GilmerHeavy.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer';
  src: url('@{path-fonts}/Gilmer/GilmerBold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer';
  src: url('@{path-fonts}/Gilmer/GilmerMedium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer';
  src: url('@{path-fonts}/Gilmer/GilmerRegular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer';
  src: url('@{path-fonts}/Gilmer/GilmerLight.otf');
  font-weight: 300;
  font-style: normal;
}

* {
  border-color: @borderColor;
  border-width: 0;
  transition: none !important;
  font-variant: normal;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  font-family: 'Gilmer', sans-serif;
  line-height: normal;
  text-shadow: none;
  box-shadow: none;
}

#root {
  height: 100%;
}

#explorics-container {
  display: grid;
  grid-template-rows: 1fr;
  min-height: 100%;
}

.company_logo {
  color: @xfactororange;
  svg {
    fill: @xfactororange;
  }
}

.logo-short {
  height: 20px;
  width: auto;
}
.logo-not-short {
  height: auto;
  width: 200px;
}

.ant-layout-sider {
  border-right: none;
}

#explorics-sider {
  &.ant-layout-sider-light {
    background: #FCFCFD;
  }
  .ant-menu-light {
    &,
    .ant-menu-sub.ant-menu-inline {
      background: #FCFCFD;
    }
    .ant-menu-submenu-open {
      .anticon {
        color: @xfactororange;
        // svg {
        //   fill: @xfactororange;
        // }
        
      }
      & > span {
        color: @xfactororange;
        & > a {
          color: @xfactororange;
          
        }
      }
    }
    .ant-menu-item,
    .ant-menu-submenu {
      .anticon {
        color: @bodyFontColor;
        // svg {
        //   fill: @bodyFontColor;
        // }
        
      }
      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow {
        color: @bodyFontColor;
      }
      span > a {
        color: var(--bodyText);
        font-size: 16px;
        font-weight: 500;
      }
      &-active,
      &-selected,
      &-open > .ant-menu-submenu-title {
        .anticon {
          color: var(--primaryColor);
          // svg {
          //   fill: @xfactororange;
          // }
          svg {
            path {
              stroke: var(--primaryColor);
            }
          }
        }
        & > span {
          color: var(--primaryColor);
          & > a {
            color: var(--primaryColor);
          }
        }
        .ant-menu-submenu-expand-icon,
        .ant-menu-submenu-arrow {
          color: var(--primaryColor);
        }
        color: var(--primaryColor);
        background: transparent;
      }
      .ant-menu-submenu-title {
        color: var(--bodyText);
        font-size: 16px;
        padding-left:20px !important;
        font-weight: 500;
        // svg {
        //   fill: @bodyFontColor;
        // }
        span{
          font-size: 16px !important;
          font-weight:500;
        }
        &:hover {
          color: var(--primaryColor);
          .anticon {
            color: var(--primaryColor);
            // svg {
            //   fill: @xfactororange;
            // }
            svg{
              path{
                stroke: var(--primaryColor);
              }
            }
          }
        }
      }
      &::after {
        background: @xfactororange;
        border-right: 7px solid @xfactororange;
        left: 0;
        right: auto;
      }
    }
  }
}

@themecolor1: #0f61db;
@themebackground: #f9f9f9;
@borderColor: #d9d9d9;

.ant-menu-dark .ant-menu-item-selected > span > a {
  color: @menu-dark-selected-item-text-color !important;
}

.ant-menu-dark .ant-menu-item-selected {
  color: @menu-dark-selected-item-text-color;
}

.ant-btn-primary {
  background: @themecolor1;
  border-color: @themecolor1;
  border-radius: 7px;
  font-weight: 500;
  height: 40px;
}
.secondary-btn {
  border: 1px solid @themecolor4;
  box-sizing: border-box;
  border-radius: 7px;
  color: @themecolor4;
  font-weight: 500;
  height: 40px;
}

.btn-type3 {
  color: @themecolor1;
  border: 1px solid @themecolor1;
  box-sizing: border-box;
  border-radius: 7px;
  height: 44px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-type3:active {
  color: @themecolor1;
  background: #fff;
  border-color: @themecolor1;
}
.btn-type3:hover {
  color: @themecolor1;
  background: #fff;
  border-color: @themecolor1;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  background: #d9d9d9;
}

label {
  font-weight: 500;
  color: @themecolor4;
}
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 2px solid @primary-color;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}
.single-element-loader {
  position: absolute;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* Transparent Overlay */
.single-element-loader:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.single-element-loader:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.single-element-loader:not(:required):after {
  content: '';
  display: block;
  position: absolute;
  font-size: 10px;
  width: 25px;
  height: 25px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  // margin-top: -0.5em;

  border: 2px solid @primary-color;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// .mapping-transfer-image{
//   background-color: rgb(255, 255, 255) !important;
//   //background: rgb(255, 255, 255) !important;
//   // display: none !important;
//   border-bottom: none !important;
// }

.ant-layout {
  background: #f8fafb;
}

.ant-table-thead > tr > th {
  background: @themebackground !important;
}

.link {
  color: @themecolor1;
  text-decoration: underline;
}

.ant-input-password {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;
}

.setting-table .ant-tabs-tab {
  font-size: 18px;
}

.new-account-model .ant-modal-footer {
  padding: 0px;
  width: 0px;
}

.ant-modal-content {
  border-radius: 12px;
}

.signup-form .ant-input,
.reset-form .ant-input {
  height: 44px;
  font-size: 18px;
  padding: 12px;
}

.login-form .ant-input {
  height: 44px;
  font-size: 18px;
}

.reset-form button {
  font-size: 18px;
}

.ant-form-item-label {
  font-size: 18px;
}
.ant-drawer-body .ant-input {
  height: 44px;
  font-size: 18px;
}
.ant-select-selection-item-content {
  font-size: 14px;
}
.ant-drawer-body .ant-picker-input {
  height: 34px;
}
.ant-drawer-body .ant-input-affix-wrapper .ant-input {
  height: 34px;
}

.ant-drawer-body .ant-select-selection-placeholder {
  padding-top: 5px !important;
}

.ant-select-selection-placeholder {
  font-size: 18px;
}

.ant-picker-input input {
  font-size: 18px;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: #fff;
}

.mapping {
  .ant-table-container {
    border-top: none !important;
  }
  .ant-table-thead .ant-table-cell {
    border-radius: 7px 7px 0 0;
  }
  .mapping-transfer-image {
    background-color: rgb(255, 255, 255) !important;
    // display: none !important;
    border-bottom: none !important;
    background: #fff !important;
  }
}

.invite-modal {
  background: none;
  .ant-select-selector {
    border: 1px solid #dedede !important;
    border-radius: 7px !important;
    height: auto !important;
    // min-height: 44px !important;
    padding: 5px;
  }
}

.map-campaign-form
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  overflow: scroll;
}
.ant-select-selector::-webkit-scrollbar {
  display: none !important;
}

.map-campaign-form .ant-select-multiple .ant-select-selection-item {
  margin-bottom: 4px;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  border-radius: 7px !important;
}

.bd-7 {
  border-radius: 7px !important;
}
.border-1 {
  border: 1px solid #d9d9d9;
}

// Yel's changes begin.
#left[_ngcontent-qge-c232] {
  background-color: #f7f9fa;
}

.style-2022 #left > div > ul > li.active > a > span {
  background-color: #f7f9fa !important;
}

.menu-icon {
  background-color: #f7f9fa;
}

.menu.accordion-menu > li.active > a p,
.menu.accordion-menu > li.active.open > a p,
.menu.accordion-menu > li.active > a,
.menu.accordion-menu > li.active.open > a {
  color: #f26539 !important;
  font-weight: 600;
}

.style-2022 li.active > a > span.menu-icon {
  color: #f26539 !important;
}

.style-2022 .menu-icon {
  color: #263a61;
}

#left[_ngcontent-qge-c232] {
  border-right: 0.5px solid #cececece;
}

.topmenu-outer {
  box-shadow: none;
  border-bottom: 0.5px solid #cccccc;
}

.topmenu-outer[_ngcontent-qge-c232]
  .left-side[_ngcontent-qge-c232]
  .material-icons-round[_ngcontent-qge-c232] {
  color: #263a61;
}

.input-group .keywest_search .global_search_container {
  border: 0.5px solid #cccccc;
}

.global_search_container[_ngcontent-qge-c226]
  .input-group-addon[_ngcontent-qge-c226] {
  border-radius: 4px !important;
  border-left: 0.5px solid #cccccc;
}

.style-2022 .keywest_search {
  background-color: #fff;
}

.style-2022 .hover-background {
  background-color: transparent;
}

.style-2022 .navbar .navbar-inner .topmenu-outer .name-role h2 {
  color: #363636;
}

.style-2022 .navbar .navbar-inner .topmenu-outer .name-role span {
  color: #515151;
}

.container_interior {
  background-color: #fff;
}

.models_panel {
  background-color: #f6f8f9;
  /* Remove border-top-left-radius */
}

.style-2022 .breadcrumb-title {
  font-weight: 600;
}

.style-2022 .dashboard-card {
  border: none;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0px 4px -2px rgba(0, 0, 0, 0.4);
}

body .style-2022 .link {
  color: #f26539;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #f7f9fa;
}

.ant-layout-sider {
  background: #f7f9fa;
  border-right: 0.5px solid var(--gray300);
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #263a61;
}

.ant-menu-dark .ant-menu-item-selected > span > a {
  color: #f26539 !important;
  font-weight: 600;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #f26539;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #263a61;
}

#explorics-container #site-layout .site-layout-background {
  background: #fff;
  border-bottom: 1px solid rgb(208, 213, 221);
}

.ant-layout {
  background: #f8fafb;
}

.ant-layout-content {
  background: #f8fafb;
  padding: 8px;
  border-radius: 8px;
}

.dashboard-link {
  color: var(--primaryColor);
  text-align: center;
  font-weight: 500;
}

.ant-btn-primary {
  background: #f26539;
  border-color: #f26539;
}

.ant-btn-primary:focus, .ant-btn-primary:hover {
  color: #fff;
  background: #F26539;
  border-color: #F26539;
}

.createNewStage {
  background: #f26539 !important;
}

// .ant-btn-secondary, .ant-btn-secondary:active, .ant-btn-secondary:focus, .ant-btn-secondary:hover {
//   background: transparent !important;
//   border: 1px solid #f26539 !important;
//   color: #f26539 !important;
// }

#mikeyai-btn {
  margin: 0 auto;
  display: flex;
  border: 1px solid #D0D5DD;
  border-radius: 24px;
  font-size: 15px;
  align-items: center;
  img, svg {
    display: block;
    height: 15px;
  }
}

#header-spread {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:100%;
  margin:0 20px;
}

#sider-burger {
  margin: 0 auto;
  border: none;
  background: transparent;
  color: @bodyFontColor;
  margin-left: 5px;
  box-shadow: none;
  svg{
    margin-right:10px;
  }
  .ant-layout-sider-collapsed & {
    margin-left: 20px;
  }
  span{
    font-size: 16px;
    font-weight: 500;
  }
}
#sider-burger.ant-btn-icon-only{
  margin-left:20px !important;
}
#demandgeneration-typography {
  display: flex;
  margin: auto 20px;
  align-items: middle;
  h5 {
    font-weight: 900;
    font-size: 20px;
    line-height: 17px;
    margin-bottom:0;
    display: flex;
    align-items:center; 
    .ant-layout-sider-collapsed & {
      display: none;
    }
  }
  img, svg {
    display: block;
    width: 24px !important;
    height: 24px !important;
    margin-right: 10px;
    margin-top: 0;
  }
}

#explorics-sider .ant-divider {
  margin: 10px;
  color: var(--gray300);
}