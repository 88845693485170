.Checked li{
    background-image: url('../../assets/Images/Checked.svg');
    background-position: 0 6px;
    background-repeat: no-repeat;
    padding-left: 25px;
    font-size: 14px;
    margin-bottom: 10px;
}
.Checked{
    margin: 0;
}
.brand-detail-form .colors .ant-input-affix-wrapper{
    padding-left: 0 !important;
    height: 32px !important;
}
.brand-detail-form .colors .ant-input-prefix div{
    width: 30px !important;
    height: 30px !important;
    border-radius: 5px;
}

.empty-color {
    border: 1px solid;
    border-style: dashed;
    color: #808080;
}
.pen-style > span.anticon{
   cursor: pointer !important;
}
.custom-button-style {
    border: 1px solid;
    height: 40px;
    width: 87px;
    border-radius: 7px;
}

.custom-info-icon {
    margin-top: 3px;
}